import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger);

const split_left = gsap.utils.toArray('.animate_left');
gsap.set(split_left, {autoAlpha: 0, x: 250});

split_left.forEach((box_left, i) => {
  const anim = gsap.to(box_left, {duration: 0.5, autoAlpha: 1, x: 0, paused: true});
  
ScrollTrigger.create({
    trigger: box_left,
    start: "top 80%",
    end: "bottom 10%",
    once: false,
    onEnter: self => {
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});

const split_right = gsap.utils.toArray('.animate_right');
gsap.set(split_right, {autoAlpha: 0, x: -250});

split_right.forEach((box_right, i) => {
  const anim = gsap.to(box_right, {duration: 0.5, autoAlpha: 1, x: 0, paused: true});
  
ScrollTrigger.create({
    trigger: box_right,
    start: "top 80%",
    end: "bottom 10%",
    once: false,
    onEnter: self => {
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});

const animate_up = gsap.utils.toArray('.animate_up');
gsap.set(animate_up, {autoAlpha: 0, y: 50});

animate_up.forEach((pop_up, i) => {
  const anim = gsap.to(pop_up, {duration: 1, autoAlpha: 1, y: 0, paused: true});
  
ScrollTrigger.create({
    trigger: pop_up,
    start: "top 80%",
    end: "bottom 10%",
    once: false,
    onEnter: self => {
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});

const fade_in = gsap.utils.toArray('.fade_in');
gsap.set(fade_in, {autoAlpha: 0});

fade_in.forEach((fade_me, i) => {
  const anim = gsap.to(fade_me, {duration: 0.5, autoAlpha: 1, paused: true});
  
ScrollTrigger.create({
    trigger: fade_me,
    end: "top top",
    once: false,
    onEnter: self => {
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});
